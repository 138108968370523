export const DownloadingResults = () => {
  return (
    <div role="status" className="h-full w-full animate-pulse px-4 py-6">
      <div className="mb-4 h-4 w-60 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="divide-y divide-gray-200">
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
      </div>
      <div className="mt-4 h-4 w-60 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const LoadingRow = () => (
  <div className="flex items-center justify-between py-4">
    <div className="mx-1 h-3 w-24 rounded-full bg-gray-200 dark:bg-gray-700"></div>
    <div className="mx-1 h-3 w-24 rounded-full bg-gray-200 dark:bg-gray-700"></div>
    <div className="mx-1 h-3 w-24 rounded-full bg-gray-200 dark:bg-gray-700"></div>
    <div className="mx-1 h-3 w-24 rounded-full bg-gray-200 dark:bg-gray-700"></div>
    <div className="mx-1 h-3 w-24 rounded-full bg-gray-200 dark:bg-gray-700"></div>
    <div className="mx-1 h-3 w-24 rounded-full bg-gray-200 dark:bg-gray-700"></div>
  </div>
);
